import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-characteristics',
  templateUrl: './characteristics.component.html',
  styleUrls: ['./characteristics.component.scss']
})
export class CharacteristicsComponent implements OnInit {
  public phone = "+50223010802";
  public title = "Hola Edukcare, pueden enviarme informacion?";

  selectedRole: 'teacher' | 'student' | 'parent' | 'staff' = 'teacher';
  transform: number = 100;
  selectedIndex = 0;
  clientsImgsArr: any[];
  slideConfig = { slidesToShow: 4, slidesToScroll: 4 };
  staffImgs: any[];
  environment = environment;

  constructor() { }

  ngOnInit(): void {
    this.clientsImgsArr = [
      [
        {
          img: 'assets/images/phone-01.png',
          text: 'Aquí va el nombre del colegio',
        },
        {
          img: 'assets/images/phone-01.png',
          text: 'Aquí va el nombre del colegio',
        },
        {
          img: 'assets/images/phone-01.png',
          text: 'Aquí va el nombre del colegio',
        },
        {
          img: 'assets/images/phone-01.png',
          text: 'Aquí va el nombre del colegio',
        },
      ]
    ];
    this.staffImgs = [
      {
        img: 'assets/images/boy-01.png',
        name: 'Miembro de Staff',
        text: 'Este es el mejor trabajador',
      },
      {
        img: 'assets/images/girl-01.png',
        name: 'Miembro de Staff',
        text: 'Este es el mejor trabajador',
      },
      {
        img: 'assets/images/boy-01.png',
        name: 'Miembro de Staff',
        text: 'Este es el mejor trabajador',
      },
      {
        img: 'assets/images/girl-01.png',
        name: 'Miembro de Staff',
        text: 'Este es el mejor trabajador',
      },
    ]
  }

}
