import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HomeModule } from './pages/home/home.module';
import { RegisterModule } from './pages/register/register.module';
import { HttpClientModule } from "@angular/common/http";
import { ToastyModule } from 'ng2-toasty';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EdukcareModule } from  './edukcare/edukcare.module'

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    HomeModule,
    EdukcareModule,
    RegisterModule,
    ToastyModule,
  ],
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
