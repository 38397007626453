import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mainServiceUrl } from '../config';
import { take } from "rxjs/operators";
import { SignUpRequest } from '../models/sign-up-request.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http: HttpClient,
  ) { }

  registerNewCustomer(newCustomer: SignUpRequest) {
    return this.http.post(`${mainServiceUrl}/Api/V1/Customers/SignUp`, {
      app_id: 'app',
      data: newCustomer
    })
      .pipe(take(1));
  }

  getCountries()  {
    return this.http.get(`${mainServiceUrl}/Api/V1/Countries/all`);
  }

}
