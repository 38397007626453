import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdukcareComponent } from './edukcare.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";
import { SchoolModuleModule } from '../pages/school/school.module';
import { CharacteristicsModule } from '../pages/characteristics/characteristics.module';
import { SupportModule } from '../pages/support/support.module';
import { HeaderModule } from '../shared/header/header.module';
import { FooterModule } from '../shared/footer/footer.module';
import { RegisterModule } from '../pages/register/register.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        SchoolModuleModule,
        CharacteristicsModule,
        SupportModule,
        NgxWhastappButtonModule,
        HeaderModule,
        FooterModule,
        RegisterModule
    ],
  declarations: [
    EdukcareComponent,
  ],
  providers: [
    EdukcareComponent,
  ],
})
export class EdukcareModule { }
