import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoFooterComponent } from './logo-footer.component';
import { AppRoutingModule } from 'src/app/app-routing.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
  ],
  declarations: [
    LogoFooterComponent
  ],
  providers: [
    LogoFooterComponent
  ],
  exports: [
    LogoFooterComponent
  ],
})
export class LogoFooterModule { }
