import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolComponent } from './school.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
  ],
  declarations: [
    SchoolComponent,
  ],
  providers: [
    SchoolComponent,
  ],
  exports: [
    SchoolComponent
  ]
})
export class SchoolModuleModule { }
