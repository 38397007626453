import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportComponent } from './support.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule
  ],
  declarations: [
    SupportComponent,
  ],
  providers: [
    SupportComponent,
  ],
  exports: [
    SupportComponent
  ]
})
export class SupportModule { }
