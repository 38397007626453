export const environment = {
  production: true,
  environmentName: 'dev',
  apiUrl: 'https://api-dev.edukcare.com',
  adminUrl: 'https://admin-dev.edukcare.com',
  bot: {
    url: 'https://chat.lnk.dev.assurehub.io/chat-widget.umd.js',
    id: 'e4b10037-ccc0-485c-9dae-844b1ab102cd',
    toolbarTitle: 'Edukcare sales bot',
    toolbarUrl: 'https://dev.edukcare.com/assets/images/Logo-Edukcare-RRSS-03.png',
    avatarRight: 'https://dev.edukcare.com/assets/images/Logo-Edukcare-RRSS-04.png',
    avatarLeft: 'https://dev.edukcare.com/assets/images/Logo-Edukcare-RRSS-04.png'
  }
};
