import {Component, OnDestroy, OnInit} from '@angular/core';
import {menuOption} from '../models/gobalEnum';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-edukcare',
  templateUrl: './edukcare.component.html',
  styleUrls: ['./edukcare.component.scss']
})
export class EdukcareComponent implements OnInit, OnDestroy {
  private scriptElement: HTMLScriptElement;
  constructor() { }
  openRegister = false;
  optionMenu: menuOption;
  register = 0;

  saveRegister(): void{
    this.register = this.register + 1;
  }
  clickMenu(opt: menuOption): void{
    this.optionMenu = opt;
  }

  ngOnInit(): void {
    this.optionMenu = menuOption.customer;
    this.scriptElement = document.createElement('script');
    this.scriptElement.src = environment.bot.url;
    this.scriptElement.type = 'module';
    this.scriptElement.async = true;
    this.scriptElement.onload = () => {
      // @ts-ignore
      if (window.ChatWidget) {
        // @ts-ignore
        window.ChatWidget.initializeChatWidget({
          containerId: 'chat-widget-container',
          appId: environment.bot.id,
          secMode: 'internal_auth',
          position: 'BOTTOM_RIGHT',
          toolbarTitle: environment.bot.toolbarTitle,
          toolbarURL: environment.bot.toolbarUrl,
          avatarRight: environment.bot.avatarRight,
          avatarLeft: environment.bot.avatarLeft,
          clientId: environment.bot.id
        });
      }
    };
    document.body.appendChild(this.scriptElement);
  }

  ngOnDestroy(): void {
    if (this.scriptElement) {
      document.head.removeChild(this.scriptElement);
    }
  }

}
