import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterRoutingModule } from './register-routing.module';
import { ToastyModule } from 'ng2-toasty';

@NgModule({
    imports: [
        CommonModule,
        RegisterRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ToastyModule,
    ],
    declarations: [
        RegisterComponent
    ],
    providers: [
        RegisterComponent,
    ],
    exports: [
        RegisterComponent
    ]
})
export class RegisterModule { }
