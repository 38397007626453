import {Component, Input, OnInit} from '@angular/core';
import {menuOption} from '../../models/gobalEnum';
@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit {
  @Input() optionMenu: menuOption;
  constructor() { }

  ngOnInit(): void {}

}
