import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    NgxWhastappButtonModule
  ],
  declarations: [
    HomeComponent,
  ],
  providers: [
    HomeComponent,
  ],
})
export class HomeModule { }
