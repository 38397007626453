import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LogoFooterModule } from '../logo-footer/logo-footer.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    LogoFooterModule,
  ],
  declarations: [
    FooterComponent
  ],
  providers: [
    FooterComponent
  ],
  exports: [
    FooterComponent
  ],
})
export class FooterModule { }
