export enum menuOption {
    customer = 'customer',
    school = 'school',
    characteristics = 'characteristics',
    support = 'support'
}


export enum SchoolTypes {
    PRIVATE_SCHOOL = 'Escuela / Academia Privada',
    PUBLIC_SCHOOL = 'Escuela / Academia Pública',
    ENTERPRISE = 'Empresa privada',
    GOV_ENTERPRISE = 'Institución Pública / Gobierno'
}
