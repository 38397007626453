import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./edukcare/edukcare-routing.module').then(m => m.EdukcareRoutingModule),
  },
  {
    path: 'd',
    loadChildren: () => import('./pages/home/home-routing.module').then(m => m.HomeRoutingModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register-routing.module').then(m => m.RegisterRoutingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
