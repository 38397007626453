import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LogoHeaderModule } from '../logo-header/logo-header.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    LogoHeaderModule,
  ],
  declarations: [
    HeaderComponent
  ],
  providers: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
})
export class HeaderModule { }
