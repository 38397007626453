import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from 'src/environments/environment';
import {menuOption} from 'src/app/models/gobalEnum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() clickMenu: EventEmitter<menuOption> = new EventEmitter();
  @ViewChild('closebutton') closebutton;
  environment = environment;
  options = menuOption
  activeMenu: menuOption;

  constructor() { }

  ngOnInit(): void {
    this.activeMenu = menuOption.customer;
  }

  singIn(): void{
    document.location.href = environment.adminUrl;
  }

  closeMenu(): void {
    this.closebutton.nativeElement.click();
  }
  setMenu(option: menuOption): void{
    this.activeMenu = option
    this.clickMenu.emit(option);
  }
}
