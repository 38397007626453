import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-footer',
  templateUrl: './logo-footer.component.html',
  styleUrls: ['./logo-footer.component.scss']
})
export class LogoFooterComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
